import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import Icon from '../../components/Icon'
import { H2, P } from '../../styleElements'
import mq from '../../utils/mediaQuery'

const Card = ({ name, img, desc, link, color }) => {
  return (
    <Root href={link} target="_blank">
      <Container>
        <ImageWrapper>
          <Img fluid={img} objectFit="cover" objectPosition="50% 50%" />
        </ImageWrapper>
        <Header>
          <Name>{name}</Name>
          <Content>{desc}</Content>
        </Header>
        <Footer>
          <IconWrapper>
            <EventIcon name="external_link" size={24} />
            <EventIcon name="events_fb" size={24} />
          </IconWrapper>
          <BottomBar color={color}></BottomBar>
        </Footer>
      </Container>
    </Root>
  )
}

Card.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  desc: PropTypes.string.isRequired,
  link: PropTypes.string,
  color: PropTypes.string.isRequired
}

const Root = styled.a`
  border-radius: 5px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  background-color: ${props => props.theme.color.white};

  ${mq.md} {
    width: 22%;
  }
`

const Container = styled.article`
  display: flex;
  flex-direction: column;
  min-height: 380px;
`

const Header = styled.header`
  flex-grow: 1;
  padding: 0 20px;
  word-break: break-word;
`

const ImageWrapper = styled.div`
  width: 100%;
`

const Name = styled(H2)`
  padding: 20px 0;
`

const Content = styled(P)``

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
`

const EventIcon = styled(Icon)`
  margin-left: 5px;
`

const BottomBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${props => props.color};
  margin: 0 auto;
`

export default Card
